.usePlaceholderTextField input::-webkit-input-placeholder {
    font-size: 18px;
    font-family: 'Roboto', 'Arial', 'Sans-serif' !important;
}

.usePlaceholderTextField input:-ms-input-placeholder {
    font-size: 18px;
    font-family: 'Roboto', 'Arial', 'Sans-serif' !important;
}

.usePlaceholderTextField input::placeholder {
    font-size: 18px;
    font-family: 'Roboto', 'Arial', 'Sans-serif' !important;
}

.usePlaceholder::-webkit-input-placeholder {
    font-size: 18px;
    font-family: 'Roboto', 'Arial', 'Sans-serif';
}

.usePlaceholder:-ms-input-placeholder {
    font-size: 18px !important;
    font-family: 'Roboto', 'Arial', 'Sans-serif';
}

.usePlaceholder::placeholder {
    font-size: 18px !important;
    font-family: 'Roboto', 'Arial', 'Sans-serif';
}

.usePlaceholderTextField input {
    height: inherit;
    box-sizing: border-box;
}

.usePlaceholderTextArea {
    min-height: 37px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    margin-top: 5px;
    padding: 8px;
    font-family: 'Roboto', 'Arial', 'Sans-serif';
}

.usePlaceholderTextArea:focus-visible {
    outline: none;
}

.transparentPlaceholder input::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.38) !important;
}

.transparentPlaceholder input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38) !important;
}

.transparentPlaceholder input::placeholder {
    color: rgba(0, 0, 0, 0.38) !important;
}

.transparentPlaceholder::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.38) !important;
}

.transparentPlaceholder:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38) !important;
}

.transparentPlaceholder::placeholder {
    color: rgba(0, 0, 0, 0.38) !important;
}

.errorPlaceholder input::-webkit-input-placeholder {
    color: #f44336 !important;
}

.errorPlaceholder input:-ms-input-placeholder {
    color: #f44336 !important;
}

.errorPlaceholder input::placeholder {
    color: #f44336 !important;
}

.errorPlaceholder::-webkit-input-placeholder {
    color: #f44336 !important;
}

.errorPlaceholder:-ms-input-placeholder {
    color: #f44336 !important;
}

.errorPlaceholder::placeholder {
    color: #f44336 !important;
}

.errorPlaceholder input {
    color: #f44336 !important;
}
